import React from 'react';
import StatusUpdates from '../StatusUpdates';

const ParttimerDashboard = () => {
  return (
    <div>
      <StatusUpdates />
    </div>
  );
};

export default ParttimerDashboard;
