import React from 'react';

export const Ams = () => {
  return (
    <div className="">
      <div className="container mt-3">
        <h1 className="main-heading">Anddhen Marketing Services</h1>
      </div>
    </div>
  );
};
